<template lang="pug">
.container-fluid.px-3
  .row.mb-4
    .col
      om-heading.mt-1(h1) {{ $t('changePassword') }}
  .row
    .col-5.col-lg-3.mt-1.position-static
      form(ref="form")
        .form-group.row
          .col-12
            om-input#curr_pass(
              v-model="currentPassword"
              type="password"
              :label="$t('currentPassword')"
              ref="currentPassword"
            )
              template(
                slot="error"
                v-if="$v.currentPassword.$error && !$v.currentPassword.required"
              )
                span {{ $t('requiredField') }}
        .form-group.row
          .col-12
            om-input#new_pass(v-model="newPassword" type="password" :label="$t('newPassword')")
              template(slot="error" v-if="$v.newPassword.$error && !$v.newPassword.required")
                span {{ $t('requiredField') }}
              template(slot="error" v-if="$v.newPassword.$error && !$v.newPassword.minLength")
                span {{ $t('passwordLength', { length: $v.newPassword.$params.minLength.min }) }}
        .form-group.row
          .col-12
            om-input#new_pass_confirm(
              v-model="newPasswordAgain"
              type="password"
              :label="$t('newPasswordAgain')"
            )
              template(
                slot="error"
                v-if="$v.newPasswordAgain.$error && !$v.newPasswordAgain.required"
              )
                span {{ $t('requiredField') }}
              template(
                slot="error"
                v-if="$v.newPasswordAgain.$error && !$v.newPasswordAgain.isCool"
              )
                span {{ $t('passwordMismatch') }}
        .form-group.row
          .col-12.mt-1
            om-button(primary @click.prevent="save()") {{ $t('save') }}
</template>

<script>
  import CHANGE_PASSWORD from '@/graphql/ChangePassword.gql';
  import { required, minLength } from 'vuelidate/lib/validators';

  export default {
    data() {
      return {
        currentPassword: '',
        newPassword: '',
        newPasswordAgain: '',
      };
    },

    validations: {
      currentPassword: {
        required,
      },
      newPassword: {
        required,
        minLength: minLength(8),
      },
      newPasswordAgain: {
        required,
        isCool(value) {
          return value === this.newPassword;
        },
      },
    },

    methods: {
      save() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
        } else {
          this.$apollo
            .mutate({
              mutation: CHANGE_PASSWORD,
              variables: {
                oldPassword: this.currentPassword,
                newPassword: this.newPassword,
              },
            })
            .then((result) => {
              const response = result.data.changePassword;
              const success = response?.success === undefined ? !!result : response?.success;

              if (success) {
                this.$refs.currentPassword.$el.classList.remove('is-invalid');
                this.$refs.form.reset();
                this.$notify({
                  type: 'success',
                  text: this.$t('notifications.saveSuccess'),
                });
              } else {
                this.$refs.currentPassword.$el.classList.add('is-invalid');
                const text =
                  response?.message && this.$te(response.message)
                    ? this.$t(response?.message)
                    : this.$t('notifications.incorrectCurrentPassword');
                this.$notify({ type: 'error', text });
              }
            })
            .catch(() => {
              this.$notify({
                type: 'error',
                text: this.$t('notifications.saveError'),
              });
            });
        }
      },
    },
  };
</script>
